<template>
  <b-card no-body>
    <b-card-body class="mb-0 pb-0">
      <b-card-title>
        Student Profiles

        <span class="float-right">
          <b-button v-b-modal.modal-add-student size="sm" block variant="primary">Add Student</b-button>
        </span>
      </b-card-title>
      <hr />
    </b-card-body>

    <b-col lg="12">
      <p class="text-center" v-if="cohortData.profiles.length === 0">There are no profiles in this cohort.</p>
    </b-col>

    <b-row>
      <b-col cols="12" sm="12">
        <div class="m-1 mt-0 mb-0">
          <p class="d-inline">
            Showing {{ (profilesPage - 1) * profilesPerPage + 1 }} -
            {{ (profilesPage - 1) * profilesPerPage + displayedProfiles.length }} out of {{ profilesTotal }} profiles
          </p>

          <b-pagination
            v-model="profilesPage"
            :per-page="profilesPerPage"
            :total-rows="profilesTotal"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="float-right"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-col>

      <b-col lg="4" md="6" sm="12" v-for="(item, index) in displayedProfiles" :key="item.id">
        <b-card no-body class="border-dark m-1">
          <b-card-body style="padding: 0.5rem">
            <b-link
              :to="{ name: 'apps-students-view', params: { id: item.student.id } }"
              class="font-weight-bold text-nowrap"
            >
              <b-badge variant="primary">#{{ item.student.studentId }}</b-badge>
              {{ item.student.fullName }}
            </b-link>

            <span class="float-right">
              <b-badge variant="info">
                {{ item.priority }}
                <feather-icon v-if="item.recommended" icon="StarIcon" style="color: #ffd700"></feather-icon>
              </b-badge>
            </span>

            <b-row style="margin-top: 0.3rem">
              <b-col md="12">
                <b-button-group size="sm" block class="w-100">
                  <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" @click="viewProfile(item.id)">
                    View
                  </b-button>
                  <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" @click="editProfile(item.id)">
                    Edit
                  </b-button>
                  <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" @click="removeProfile(item.id)">
                    Remove
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>

            <hr style="margin: 0.5rem" />

            <b-row>
              <b-col lg="12" sm="12">
                <b-badge v-if="item.attributes.clearance !== 'none'" class="attr-tag" outline variant="light-primary">
                  {{ title(item.attributes.clearance) }}
                </b-badge>
                <b-badge v-if="item.attributes.veteran" class="attr-tag" outline variant="light-info"> Veteran </b-badge>
              </b-col>

              <b-col lg="12" sm="12" style="margin-top: 5px; margin-bottom: 5px">
                <b-list-group>
                  <b-list-group-item
                    v-if="!item.certificates || item.certificates.length === 0"
                    class="d-flex font-small-2"
                    style="padding: 3px"
                  >
                    No Certifications
                  </b-list-group-item>
                  <b-list-group-item
                    v-else
                    v-for="(cert, index) in renderedCertificates(item)"
                    :key="index"
                    class="d-flex"
                    style="padding: 3px"
                  >
                    <b-badge
                      v-if="cert.expiresAt"
                      :variant="`light-${resolveVariant(new Date(cert.expiresAt))}`"
                      pill
                      class="badge-round font-small-1"
                    >
                      {{ moment(cert.expiresAt).format("MMM 'YY") }}
                    </b-badge>
                    <b-badge v-else variant="light-info" pill class="badge-round font-small-1"> Non-Expiring </b-badge>

                    <span class="font-small-2" style="margin-left: 2.5px">{{ cert.name }}</span>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="modal-add-student"
      cancel-variant="outline-secondary"
      ok-title="Add Student"
      cancel-title="Close"
      centered
      title="Add student to cohort"
      @ok="doSelectInstructor"
    >
      <vue-autosuggest
        ref="autocomplete"
        v-model="instructorQuery"
        :suggestions="instructorSuggestions"
        :input-props="instructorInputProps"
        :section-configs="instructorSectionConfigs"
        :render-suggestion="instructorRenderSuggestion"
        :get-suggestion-value="instructorGetSuggestionValue"
        @input="instructorFetchResults"
      />
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BRow,
  BCol,
  BButton,
  BBadge,
  BLink,
  BPagination,
  BListGroup,
  BListGroupItem,
  BButtonGroup,
} from 'bootstrap-vue';
import { onUnmounted, ref } from '@vue/composition-api';
import { VueAutosuggest } from 'vue-autosuggest';
import store from '@/store';
import studentStoreModule from '@/views/apps/student/studentStoreModule';
import router from '@/router';
import { title } from '@core/utils/filter';
import moment from 'moment';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BBadge,
    BLink,
    BPagination,
    BListGroup,
    BListGroupItem,
    BButtonGroup,
    VueAutosuggest,
  },
  directives: {
    Ripple,
  },
  props: {
    cohortData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    resolveVariant(date) {
      const now = new Date();
      if (date < now) return 'dark';

      const d = new Date(now);
      d.setDate(d.getDate() + 30);
      if (date < d) return 'danger';

      d.setDate(d.getDate() + 30);
      if (date < d) return 'warning';

      return 'success';
    },
    openAddStudent() {
      const { id } = this.cohortData;

      console.log(`Open add new student for cohort ${id}`);
    },
    viewProfile(profileId) {
      this.$router.push({ name: 'apps-placement-profile-view', params: { id: profileId } });
    },
    editProfile(profileId) {
      this.$router.push({ name: 'apps-placement-profile-edit', params: { id: profileId } });
    },
    removeProfile(profileId) {
      console.log(`Remove profile from cohort`, profileId);

      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to remove this profile from this cohort?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remove',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch('app-placement/removeStudentsFromCohort', {
              cohortId: this.cohortData.id,
              profileIds: [profileId],
            })
            .then((response) => {
              this.$emit('refresh');

              this.$swal({
                icon: 'success',
                title: 'Removed!',
                text: 'The student has been removed',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              });
            })
            .catch((ex) => {
              console.error(ex);
            });
        }
      });
    },

    async doSelectInstructor() {
      if (!this.instructorSelected) {
        return;
      }

      this.$store
        .dispatch('app-placement/addStudentsToCohort', {
          cohortId: this.cohortData.id,
          studentIds: [this.instructorSelected.id],
        })
        .then((response) => {
          console.log(`updated course`, response);

          console.log(response.data);

          this.$emit('refresh');

          this.instructorQuery = undefined;
          this.instructorFetchResults();
        })
        .catch((e) => {
          console.error(`failed to update course`, e);
        });
    },

    instructorFetchResults() {
      const { query } = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        const usersPromise = this.$store.dispatch('app-student/fetchStudents', {
          search: this.instructorQuery,
        });

        Promise.all([usersPromise]).then((values) => {
          this.instructorSuggestions = [];

          const users = values[0].data.results;

          users.length && this.instructorSuggestions.push({ name: 'users', data: users });
        });
      }, 250);
    },
    instructorRenderSuggestion(row) {
      console.log(`render`, row);
      return `${row.item.studentId} ${row.item.fullName}`;
    },
    instructorGetSuggestionValue(row) {
      if (row.item) {
        return this.instructorRenderSuggestion(row);
      }
      return `${row.studentId} ${row.fullName}`;
    },
    filterDisplayedProfiles() {
      this.displayedProfiles = this.cohortData.profiles.slice(
        (this.profilesPage - 1) * this.profilesPerPage,
        this.profilesPage * this.profilesPerPage
      );
    },
    renderedCertificates(profile) {
      if (!profile || !profile.certificates) return [];

      return profile.certificates.map((cert) => {
        return {
          id: cert.name,
          name: cert.name,
          expiresAt: cert.expiresAt,
        };
      });
    },
  },
  data: () => {
    return {
      displayedProfiles: [],
    };
  },
  watch: {
    cohortData() {
      this.profilesTotal = this.cohortData.profiles.length;
      this.filterDisplayedProfiles();
    },
    profilesPage() {
      this.filterDisplayedProfiles();
    },
  },
  mounted() {
    this.filterDisplayedProfiles();
  },
  setup(props) {
    const { cohortData } = props;

    const STUDENT_APP_STORE_MODULE_NAME = 'app-student';

    // Register module
    if (!store.hasModule(STUDENT_APP_STORE_MODULE_NAME))
      store.registerModule(STUDENT_APP_STORE_MODULE_NAME, studentStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STUDENT_APP_STORE_MODULE_NAME)) store.unregisterModule(STUDENT_APP_STORE_MODULE_NAME);
    });

    const instructorQuery = ref('');
    const instructorSuggestions = ref([]);
    const instructorSelected = ref({});
    const instructorSectionConfigs = {
      users: {
        limit: 10,
        label: 'Students',
        onSelected: (selected) => {
          instructorSelected.value = selected.item;
        },
      },
    };

    const instructorInputProps = {
      id: 'autosuggest__input_ajax',
      placeholder: 'Search for users',
      class: 'form-control',
      name: 'ajax',
    };

    const profilesTotal = ref(cohortData.profiles.length);

    const profilesPerPage = ref(12);
    const profilesPage = ref(1); // Math.ceil(profilesTotal.value / profilesPerPage.value)

    return {
      profilesPage,
      profilesPerPage,
      profilesTotal,

      instructorQuery,
      instructorSuggestions,
      instructorSectionConfigs,
      instructorInputProps,
      instructorSelected,

      title,
      moment,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';

pre {
  min-height: 295px;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
}

.attr-tag {
  float: right;
  margin: 2.5px 0 2.5px 5px;
}
</style>
